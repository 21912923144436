<script>
import axios from 'axios';
import Layout from "../layouts/horizontal";
import PageHeader from "@/components/page-header";
import {
   
} from "@/state/helpers";


export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    
    return {
      title: 'Knowledge Hub',
      breadcrumbs: [
        {
          text: 'Knowledge Hub',
          active: true
        }
      ],
      articles: []
    };
  },
  methods: {
    async fetchArticles() {
      const response = await axios.get('https://c8hedge.com/api/articles');
      this.articles = response.data
    }
  },
  created() {
    this.fetchArticles();
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" />
    
    <div class="row">
      
      <div v-for="article in articles" :key="article.id" class="col-md-4 col-sm-1 pb-4 pt-4">
        <div class="card cardc8 m-2 h-100">
          <div class="card-content m-3">
            <h4>{{ article.title }}</h4>
            <p>{{ article.date }}</p>
            <router-link :to="'/knowledgehub/' + article.id">Read more...</router-link>
          </div>
        
        </div>

        
        
      </div>
    </div>
     
    
  </Layout>
</template>